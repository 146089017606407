
import React from "react";
import "./banner.scss";






const Banner = () => {


  return (
    <>

      <section className="main-banner">
        <img src="\assets\banner\banner-bg.png" alt="img" className="img-fluid banner-bg" />
          <div className="main-content">
              <h2>the ShitCoin</h2>
              <p>The Shitiest coin ever</p>
              <div className="coming-soon">Coming soon</div>
          </div>
      </section>
    </>
  );
};

export default Banner;
