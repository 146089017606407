import { Link } from "react-router-dom";
import "./navbar.scss";


const Navbar = () => {


 
  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-xl">
            <div class="container-fluid p-0">
              <Link class="navbar-brand" to="/"><img src="\logo.svg" alt="img" className="img-fluid" /></Link>
            </div>
          </nav>
        </div>
      </section>

      


    </>
  );
};

export default Navbar;
