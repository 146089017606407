import '../../app.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar.js';





function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Navbar />
      <Banner />
    </>
  );
}

export default Landing;