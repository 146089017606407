import './app.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
          <Router>
            <Routes>
              <Route exact path='/' element={<Landing />} />
            </Routes>
          </Router>
    </>
  );
}

export default App;
